
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For Webkit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* width of the vertical scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scrollbar handle */
  border-radius: 5px; /* border radius of the scrollbar handle */
}

/* For Webkit based browsers (Chrome, Safari) */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color of the scrollbar handle on hover */
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1; /* background color of the scrollbar corner */
}

::-webkit-scrollbar-button {
  background-color: #f1f1f1; /* background color of the scrollbar buttons (up/down arrows) */
}

/* For Firefox */
/* Note: Firefox does not currently support styling of the scrollbar */

/* For Internet Explorer and Edge */
/* Note: Internet Explorer and Edge use a different syntax for scrollbar styling */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .loader-image {
    width: 100px;
    height: 100px;
  }
  